<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />

    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />
     <v-col cols="12" md="12" sm="12" class="text-end mt-6" :style="`padding-`+lang.lalgin+`:35px;`">
        <b-button
            v-b-toggle.add_new_product
            class="btn-sm btn "
            style="margin-top:11px;width:150px;background:transparent;border:none"
            id="multiply-button" 
            @click="addNewP()"
        >
        <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_product}}</div>
        
        </b-button>
    </v-col>
    <v-form ref="addpr" style="display:none;">
        <v-row class="m-4" style="border:1px solid #ccc;background:#eee;border-radius:5px;margin-left:30px !important;margin-right:30px !important;">
            <v-col cols="12" md="2" sm="12">
                <label>{{lang.item_code}}</label>
                <b-form-input
                    v-model="newrow.itemcode"
                    @change="getit()"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <label>{{lang.item_name}}</label>
                <b-form-input
                    v-model="newrow.name"
                    :rules="fieldRules"
                    class="inborder"
                    required
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="2" sm="12">
                <label>{{lang.product_categories}}</label>
                <b-form-select
                    item-text="text"
                    item-value="value"
                    v-model="newrow.catid"
                    :options="product_categories"
                    class="selborder"
                    ></b-form-select>
            </v-col>
            <v-col cols="12" md="1" sm="12">
                <label>{{lang.item_cost}}</label>
                <b-form-input
                    :label="lang.item_cost"
                    v-model="newrow.cost"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="1" sm="12">
                <label>{{lang.price}}</label>
                <b-form-input
                    :label="lang.item_price"
                    v-model="newrow.price"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="1" sm="12">
                <label>{{lang.qtty}}</label>
                <b-form-input
                    v-model="newrow.qty"
                    class="inborder"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="mt-4">
                <b-button variant="success" style="width:145px;margin-right:6px;" class="btn-sm" @click="addIt()">{{lang.add}} / {{lang.update}}</b-button>
            </v-col>
        </v-row>
    </v-form>
        <v-row class="m-2" style="margin-left:30px !important;margin-right:30px !important;">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    disable-pagination
                        hide-default-footer
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:100px;" class="text-center">{{ row.item.item_code }}</td>
                            <td style="" class="text-start" nowrap>{{ row.item.item_namear }}</td>
                            <td style="" class="text-start" nowrap>{{ row.item.item_nameen }}</td>
                            <td style="width:150px;" class="text-start" nowrap>{{ row.item._type }}</td>
                            <td style="width:150px" class="text-start" nowrap>{{ row.item.category_name }}</td>
                            <td style="width:100px" class="text-center">{{ row.item.default_price }}</td>
                            <td style="width:100px" class="text-center">{{ row.item.qty }}</td>
                            
                            <td style="width:80px;background:red; border:2px solid #fff !important;" class="text-center">
                                <v-btn style="font-size:0.2em;background:red;width:100%;color:#fff;height:25px !important;margin-left:5px;" @click="delx(row.item.id)">{{lang.delete}}</v-btn>
                            </td>
                            <td style="width:80px;background:blue; border:2px solid #fff !important;" class="text-center">
                                <v-btn style="font-size:0.2em;background:blue;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.add_new_product @click="ViewProd(row.item.item_code)">{{lang.update}}</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">
                <v-container>
                    <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        value="15"
                        :length="pageCount"
                        color="#000"
                        @input="getNext(page)"
                    ></v-pagination>
                    </div>
                </v-container>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
    </div>
    <Footer />
    <addNewProduct ref="addProducts" />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import addNewProduct from '@/components/addNewProduct.vue';
import ExportsPrint from '@/components/exports-print.vue'
export default{
  components: { breadCrumbs, TabsComp, Footer, HeaderPortrate,addNewProduct,ExportsPrint },
    name: 'Products',
    data() {
        return {
            cpage: 0,
            pageCount:0,
            active_tab:0,
            tablerows: [

            ],
            newrow: {
                itemcode: '',
                name: '',
                price: '',
                qty: '',
                catid: '',
            },
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            product_categories: [],
            page: 1,
        }
    },
    created() {
        this.getProducts();
        this.getCats();
    },
    methods: {
        delx(id){
            const post = new FormData();
            post.append("type","deleteProduct");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                this.getProducts();
                this.getCats();
            });
        },
        addNewP(){
            this.$refs.addProducts.resetAll();
        },
        ViewProd(itemcode){
            this.$refs.addProducts.resetAll();
            this.$refs.addProducts.product.item_code = itemcode;
            this.$refs.addProducts.getItem();
            this.$refs.addProducts.getTaxes();
        },
        getCats(){
            const post = new FormData();
            post.append("type","getPCatsList");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all0");
            post.append('data[lang]',this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                // // console.log('asdfasdf',p);
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push({
                                text: this.lang.langname == "ar" ? p.results.data[i].namear : p.results.data[i].nameen,
                                value: p.results.data[i].id
                            });
                        }
                    }
                }
                this.product_categories = t;
            });
        },
        addIt() {
            if(!this.$refs.addpr.validate()){
                // this.$snotify.error('يجب عليك ان تقوم باضافة اسم القطعة', '', { 
                //         timeout: 2000,
                //         showProgressBar: true,
                //         closeOnClick: false,
                //         pauseOnHover: true,
                //         leftTop:"leftTop"
                //     });
                return false
            }
            const post = new FormData();
            post.append("type","addProducts");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode);
            post.append('data[name]',this.newrow.name);
            post.append('data[nameen]',this.newrow.nameen);
            post.append('data[price]',this.newrow.price);
            post.append('data[qty]',this.newrow.qty);
            post.append('data[catid]',this.newrow.catid);
            post.append('data[cost]',this.newrow.cost);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                //// // console.log(res.data);
                this.$snotify.success('تم اضافة / تعديل الصنف بنجاح', '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                this.newrow = {
                        itemcode: '',
                        name: '',
                        nameeb: '',
                        price: 0,
                        qty: 0,
                        catid: '',
                        cost: '',
                    }
                return false;
            }).then((res) => {this.getProducts();})
            .then((res) => {this.calcTo()})
        },
        getit() {
            console.log("JER",this.tablerows);
            for(let i=0;i<this.tablerows.length;i++){
                if(this.tablerows[i].itemcode == this.newrow.itemcode){
                    this.newrow.name = this.tablerows[i].name;
                    this.newrow.nameen = this.tablerows[i].nameen;
                    this.newrow.price = this.tablerows[i].price;
                    this.newrow.qty = this.tablerows[i].qty;
                    this.newrow.catid = this.tablerows[i].catid;
                    this.newrow.cost = this.tablerows[i].cost;
                }
            }
        },
        calcTo () {
            const xy = this.$store.state.products;
            // this.tablerows = this.$store.state.products;
            const p = [];
            for(let i=0;i<xy.length;i++){
                const ss = parseFloat(xy[i].price) * parseFloat(xy[i].qty);
                let y = {
                    itemcode: xy[i].itemcode,
                    name: xy[i].name,
                    nameen: xy[i].nameen,
                    qty: xy[i].qty,
                    price: xy[i].price,
                    cost: xy[i].cost,
                    ftotal: this.$RoundNum(ss),
                    category_name: xy[i].category_name,
                    catid: xy[i].catid,
                    product_type: xy[i].product_type,
                    _product_type: xy[i].product_type == 1 ?  "صنف مخزون" : "صنف خدمي",
                };

                if(this.lang.langname == 'en' ){
                    y._product_type =  xy[i].product_type == 1 ?  "Service Product" : "Inventory Product"
                }
                
               p.push(y);
            }
            // this.tablerows = p;
        },
        getNext(page){
            this.cpage = parseInt(page) - 1;
            this.getProducts();
        },
        getProducts(){
          const post = new FormData();
          post.append('type','getProducts');
          post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
          post.append('auth',this.$cookies.get(this.$COOKIEPhase));
          post.append('data[ids]','0');
          post.append('data[cpage]',this.cpage);
          post.append('data[lang]',this.lang.langname);
          this.$store.state.products = [];
            axios.post(this.$SAMCOTEC.r_path,post)
          .then((response) => {
              const res = response.data;
              if(res.results.data.length > 0){
                // this.pageCount = parseInt(response.data.results.allres)/15;
                if(parseFloat(this.pageCount) != parseInt(this.pageCount)){
                    this.pageCount = parseInt(this.pageCount) + 1
                }
                  this.tablerows = res.results.data;
              }
            //   this.calcTo ();
          });
        },
        preparedata(){
            let exdata = {
                list: "productList",
                title: this.lang.products,
                data: [],
            }
            for(let i=0;i<this.tablerows.length;i++){
                exdata.data.push(this.tablerows[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = '';
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
            
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
        headers: function(){
            return [
                {
                    text: this.lang.item_code,
                    align: 'center',
                    filterable: false,
                    value: 'itemcode',
                    class: 'backBlack'
                },
                {
                    text: this.lang.item_name,
                    align: 'center',
                    filterable: false,
                    value: 'name',
                    class: 'backBlack'
                },
                {
                  text: this.lang.account_name_en,
                  align: 'center',
                  filterable: false,
                  value: 'name',
                  class: 'backBlack'
                },
                {
                  text: this.lang.product_type,
                  align: 'center',
                  filterable: false,
                  value: 'product_type',
                  class: 'backBlack'
                },
                {
                    text: this.lang.category_name,
                    align: 'center',
                    filterable: false,
                    value: 'price',
                    class: 'backBlack'
                },
                {
                    text: this.lang.item_price,
                    align: 'center',
                    filterable: false,
                    value: 'price',
                    class: 'backBlack'
                },
                {
                    text: this.lang.qtty,
                    align: 'center',
                    filterable: false,
                    value: 'price',
                    class: 'backBlack'
                },
                {
                    text: this.lang.delete,
                    align:'center',
                    class: 'backBlack'
                },
                {
                    text: this.lang.action,
                    align:'center',
                    class: 'backBlack'
                }
            ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.inventory,
                    disabled: true,
                    to: '/inventory/products',
                }
        }
    },
}
</script>